import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { tr } from 'date-fns/locale';

function AlertFun(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}

const Alert = React.forwardRef(AlertFun);

export const AlertDialog = ({showCancel, title, message, onOkey, onCancel}) => {

  const open = message.trim() !== "";

  let actions = null;
  if(showCancel) {
    actions = (
      <DialogActions>
        <Button onClick={() => {onCancel()}}>Cancel</Button>
        <Button onClick={() => {onOkey()}} >
          Okey
        </Button>
    </DialogActions>
    )
  }else {
    actions = (
      <DialogActions>
        <Button onClick={() => {onOkey()}} >
          Okey
        </Button>
    </DialogActions>
    )
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
        return;
    }
    onCancel();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      {actions}
    </Dialog>
  );
}



export const ProgressDialog = ({title}) => {
  
  let open = false;
  if(title) {
    open = true;
  }

  return (
    <Dialog
        open={open}
        keepMounted
        onClose={() => {}}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box justifyContent="center" sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
  );
}



/* error, warning, info, success */
export const ToastMessage = ({type, msg, y, x, duration, onClose}) => {


    console.log('ToastMessage', type, msg);
    
    let open = false;
    if(msg) {
      open = true;
    }

    return (
      <Snackbar anchorOrigin={ {vertical: y, horizontal: x} }   open={open} autoHideDuration={duration}  onClose={()=>{onClose()}}>
        <Alert onClose={()=>{onClose()}} severity={type} sx={{ width: '100%' }}>
         {msg}
        </Alert>
      </Snackbar>
    );
  }

  
export default ProgressDialog;

